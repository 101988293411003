<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Role</h4>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter un role"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <label for="city">Nom de role</label>
                <InputText
                  v-model="name"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['name'] }"
                />

                <small class="p-error" v-if="errorsAdd['name']">
                  {{ errorsAdd["name"] }}
                </small>
              </div>

              <div class="field col-12">
                <h5>Permision</h5>
                <small class="p-error" v-if="errorsAdd['permissions']">
                  {{ errorsAdd["permissions"] }}
                </small>
                <div class="grid">
                  <div
                    style="margin-top: 5px"
                    class="col-4 md:col-3"
                    v-for="permision in allPermissions"
                    :key="permision"
                  >
                    <div class="field-checkbox mb-0">
                      <Checkbox
                        id="checkOption1"
                        name="option"
                        :value="permision.id"
                        v-model="permissionsValue"
                      />
                      <label for="checkOption1">{{ permision.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfUpdate"
          :style="{ width: '900px' }"
          header="Modifier role"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <label for="city">Nom de role</label>
                <InputText
                  v-model="name"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['name'] }"
                />

                <small class="p-error" v-if="errorsAdd['name']">
                  {{ errorsAdd["name"] }}
                </small>
              </div>

              <div class="field col-12">
                <h5>Permision</h5>
                <small class="p-error" v-if="errorsAdd['permissions']">
                  {{ errorsAdd["permissions"] }}
                </small>
                <div class="grid">
                  <div
                    style="margin-top: 5px"
                    class="col-4 md:col-3"
                    v-for="permision in allPermissions"
                    :key="permision"
                  >
                    <div class="field-checkbox mb-0">
                      <Checkbox
                        id="checkOption1"
                        name="option"
                        :value="permision.id"
                        v-model="permissionsValue"
                      />
                      <label for="checkOption1">{{ permision.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfUpdate = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormup"
            />
          </template>
        </Dialog>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un nouveau role"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showdialgadd()"
              />
            </div>
          </template>
        </Toolbar>
        <DataTable
          :value="roles"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="['id', 'name', 'created_at']"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun panne trouvé. </template>
          <template #loading>
            Chargement des données panne. Veuillez patienter.
          </template>
          <Column
            field="id"
            header="Id"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.id }}
            </template>
          </Column>
          <Column
            field="name"
            header="Nom role"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column
            field="created_at"
            header="Nom role"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ formatDate(data.created_at) }}
            </template>
          </Column>

          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="showEditForm(data.data)"
                />
                <Button
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteRole(data.data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      roles: [],
      allPermissions: [],
      dialogOfAdd: false,
      dialogOfUpdate: false,
      etat: 1,
      name: null,
      errorsAdd: [],
      permissionsValue: [],

      id: null,
    };
  },
  mounted() {
    this.Permissions.update =
      localStorage.permissionNames.includes("Gestion roles");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Gestion roles");
    this.Permissions.add =
      localStorage.permissionNames.includes("Gestion roles");
    this.Permissions.show =
      localStorage.permissionNames.includes("Gestion roles");
    if (this.Permissions.show) {
      this.showAllRoles();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    this.initFilters1();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
    deleteRole(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("/destroyRole/" + id)
            .then((res) => {
              console.log(res);
              var index = this.roles
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.roles.splice(index, 1);
              this.$swal({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    checkFormup() {
      let checked = true;
      this.errorsAdd = [];
      if (this.name == null || this.name == "") {
        this.errorsAdd["name"] = "Nom de role est obligatoire.";
        checked = false;
      }
      if (this.permissionsValue.length == 0) {
        this.errorsAdd["permissions"] = "Permision est obligatoire.";
        checked = false;
      }
      if (checked) {
        this.updateRole();
      }
    },

    updateRole() {
      axios
        .post("/UpdatePermissionForRole/" + this.id, {
          nameRole: this.name,
          PermissionIds: this.permissionsValue,
        })
        .then((response) => {
          this.dialogOfUpdate = false;
          console.log("response", response);
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.showAllRoles();
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showdialgadd() {
      this.ititform();
      axios
        .get("getAllPermission")
        .then((response) => {
          this.allPermissions = response.data.permission;
          console.log(response);
          this.dialogOfAdd = true;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    ititform() {
      this.name = null;
      this.permissionsValue = [];
      this.errorsAdd = [];
    },
    showEditForm(data) {
      this.ititform();
      axios
        .get("formUpdateRole/" + data.id)
        .then((response) => {
          this.allPermissions = response.data.permissions;

          response.data.allPermissionRole.forEach((element) =>
            this.permissionsValue.push(element.id)
          );
          this.id = data.id;
          this.name = response.data.role.name;
          this.dialogOfUpdate = true;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addRole() {
      axios
        .post("/addRoleAssignPermissionEx", {
          nameRole: this.name,
          PermissionIds: this.permissionsValue,
        })
        .then((response) => {
          this.dialogOfAdd = false;
          console.log("response", response);
          this.$swal({
            icon: "success",
            title: response.data.messsage,
            showConfirmButton: false,
            timer: 1500,
          });
          this.showAllRoles();
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];
      if (this.name == null || this.name == "") {
        this.errorsAdd["name"] = "Nom de role est obligatoire.";
        checked = false;
      }
      if (this.permissionsValue.length == 0) {
        this.errorsAdd["permissions"] = "Permision est obligatoire.";
        checked = false;
      }
      if (checked) {
        this.addRole();
      }
    },

    showAllRoles() {
      axios
        .get("getAllRoles")
        .then((response) => {
          this.roles = response.data.roles;
          console.log(response);
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>